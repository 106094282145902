<template>
	<div class="notice_popup">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<div class="tit">NCS 직무자가평가 요청하기</div>
		<div class="member">
			<tec-summary v-model="data" :tecMberSeq="param.tecMberSeq" after="님에게" :showGrade="false"/><br/>
			NCS 직무자가평가를 요청 하시겠습니까?
		</div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close', false)">취소</div>
			<div class="btn" @click="reqNcs()">예</div>
		</div>
	</div>
</template>
<script>
import tecSummary from "@/components/highpro/TecSummary.vue";
export default {
	components: {tecSummary},
	props: { param:Object },
	data() {
		return {
			data: {},
			tecInfo : {},
		};
	},
	mounted(){
	},
	methods: {
		reqNcs(){
			//console.log('reqNcs', this.data)
			this.$.httpPost('/api/mem/adm/tec/reqNCS', {recvMberSeq:this.param.tecMberSeq})
				.then(() => {
					alert('NCS 평가요청을 완료하였습니다.');
					this.$emit('close', true);
				})
				.catch(this.$.httpErrorCommon);
		}
	},
};
</script>
